/* eslint-disable react/jsx-newline */
import React, { useState } from 'react'

import logoNormandie from '@assets/svg/logo-region-normandie.svg'
import logoUE from '@assets/svg/logo-ue-fonds-region.svg'
import { Box, Link as ChakraUiLink, Divider, Image, Text } from '@chakra-ui/react'
import { useMediaQuery } from '@chakra-ui/react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import Link from '@components/ui/Link'
import { device } from '@helpers/mediaQueryBreakpoints'
import { navigate } from 'gatsby'

import Logo from '../Logo'
import Section from '../Section'
import CollapsibleNav from './CollapsibleNav'
import links from './dataNav.json'

const Footer = () => {
    const [isMobile] = useMediaQuery(device.xs)
    const [isTablet] = useMediaQuery(device.tb)

    const [isLegalNoticeModalOpen, setLegalNoticeModalOpen] = useState(false)
    const [isCreditsModalOpen, setCreditsModalOpen] = useState(false)
    const [isPrivacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false)

    return (
        <Section
            bg="white"
            itemScope
            itemType="https://www.schema.org/SiteNavigationElement"
            footer={
                <>
                    {isTablet &&
                        links.map(({ id, title, dataNav }) => (
                            <CollapsibleNav key={id} title={title} dataNav={dataNav} />
                        ))}

                    <Box bg="indigo.100" display="flex" alignItems="center" justifyContent="center" py="1.5">
                        <ChakraUiLink
                            itemProp="url"
                            fontSize="xs"
                            color="indigo.500"
                            onClick={() => setLegalNoticeModalOpen(true)}
                        >
                            Mentions légales
                        </ChakraUiLink>

                        <Divider
                            orientation="vertical"
                            mx="2"
                            borderColor="indigo.500"
                            borderWidth="1px"
                            height="2.5"
                        />

                        <ChakraUiLink
                            itemProp="url"
                            fontSize="xs"
                            color="indigo.500"
                            onClick={() => setCreditsModalOpen(true)}
                        >
                            Crédits
                        </ChakraUiLink>

                        <Divider
                            orientation="vertical"
                            mx="2"
                            borderColor="indigo.500"
                            borderWidth="1px"
                            height="2.5"
                        />

                        <ChakraUiLink
                            itemProp="url"
                            fontSize="xs"
                            color="indigo.500"
                            onClick={() => navigate('/privacyPolicy')}
                        >
                            Politique de confidentialité
                        </ChakraUiLink>
                    </Box>

                    <Modal isCentered isOpen={isLegalNoticeModalOpen} onClose={() => setLegalNoticeModalOpen(false)}>
                        <ModalOverlay />

                        <ModalContent>
                            <ModalHeader>
                                <Text fontWeight="bold" color="indigo.500">
                                    Mentions légales
                                </Text>
                            </ModalHeader>

                            <ModalCloseButton />

                            <ModalBody>
                                <Text color="indigo.500" fontSize="sm" mb="6">
                                    Le site Staffea.com est édité par la société LIMENGO, SAS au capital social de 37
                                    500 €
                                    <br />
                                    <br />
                                    Campus Effiscience – 8, rue Léopold Sédar Senghor – 14460 Colombelles -
                                    contact@staffea.com
                                    <br />
                                    <br />
                                    Directeur de la publication : <Text variant="bold">Mathieu Mosquet</Text>
                                    <br />
                                    <br /> Le site Staffea.com est hébergé par OVH <br />
                                    <br /> 2, rue Kellermann – 59100 Roubaix – France
                                </Text>
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    <Modal isCentered isOpen={isCreditsModalOpen} onClose={() => setCreditsModalOpen(false)}>
                        <ModalOverlay />

                        <ModalContent>
                            <ModalHeader>
                                <Text fontWeight="bold" color="indigo.500">
                                    Crédits
                                </Text>
                            </ModalHeader>

                            <ModalCloseButton />

                            <ModalBody>
                                <Text color="indigo.500" fontSize="sm" mb="6">
                                    <Text variant="bold" display="block">
                                        © STAFFEA 2024
                                    </Text>
                                    <br />
                                    Le site Staffea.com a été développé par la société LIMENGO.
                                    <br />
                                    La charte graphique a été réalisée par{' '}
                                    <Text variant="bold">Alexandre Andurand.</Text> <br />
                                    <ChakraUiLink color="pink.500" href="https://www.alexandre-andurand.com/">
                                        www.alexandre-andurand.com
                                    </ChakraUiLink>
                                </Text>
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    <Modal
                        isCentered
                        isOpen={isPrivacyPolicyModalOpen}
                        onClose={() => setPrivacyPolicyModalOpen(false)}
                    >
                        <ModalOverlay />
                    </Modal>
                </>
            }
        >
            <Box
                pt="8"
                pb="5"
                width="100%"
                gap="12"
                display={isTablet ? 'block' : 'flex'}
                flexDirection="row"
                justifyContent={'center'}
            >
                <Box
                    mx="auto"
                    flex={1}
                    display="flex"
                    flexDirection={isTablet ? 'row' : 'column'}
                    justifyContent="center"
                    alignItems="end"
                    gap={isTablet && !isMobile ? 35 : 0}
                >
                    <Box display="flex" flexDirection="column">
                        <Logo margin="0 0 15px" width="150px" />

                        <Text fontSize="xs" color="indigo.500">
                            ©Limengo
                        </Text>

                        <Text fontSize="xs" color="indigo.500">
                            8 rue Léopold Sédar-Senghor
                        </Text>

                        <Text fontSize="xs" color="indigo.500">
                            14460 Colombelles
                        </Text>
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Text fontSize="xs" fontWeight="bold" color="indigo.500" mt="3">
                            Made In Normandie
                        </Text>

                        <Box display="flex" ml={-1}>
                            <Image src={logoNormandie} alt="Logo Région Normandie" objectFit="contain" width={90} />

                            <Image
                                src={logoUE}
                                alt="Logo Union Européenne fonds de développement régional"
                                objectFit="contain"
                                width={90}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" gap={10} flex={3} justifyContent="flex-end">
                    {!isTablet &&
                        links.map(({ id, title, dataNav }) => (
                            <Box key={id.toString() + title} minW="200px" margin="auto" marginTop="unset">
                                <Text fontSize="sm" fontWeight="bold" color="indigo.500" mb="1.5">
                                    {title}
                                </Text>

                                {dataNav.map(function ({ subTitle, link }, index) {
                                    return (
                                        <Box itemProp="name" key={index.toString() + subTitle} mb="1.5">
                                            <Link itemProp="url" to={link} fontSize="xs" color="indigo.500">
                                                {subTitle}
                                            </Link>
                                        </Box>
                                    )
                                })}
                            </Box>
                        ))}
                </Box>
            </Box>
        </Section>
    )
}

export default Footer

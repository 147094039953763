import React from 'react'

import { Button, Flex, Text } from '@chakra-ui/react'

interface Props {
    text: string
    description?: string
    width?: string
    height?: string
    color?: string
    textColor?: string
    hoverColor?: string
    hoverTextColor?: string
    onClick?: () => void
    mt?: string
    textButtonFontSize?: string
}

const CtaButton = ({
    text,
    description,
    width = '100%',
    height = '12',
    color = 'pink.500',
    textColor = 'white',
    hoverColor = 'indigo.500',
    hoverTextColor = 'white',
    textButtonFontSize = 'xs',
    onClick,
    ...rest
}: Props) => (
    <Flex
        direction="column"
        w={width}
        color={color}
        sx={{
            '&:hover': {
                color: hoverColor,
                '& button': {
                    bg: hoverColor,
                    color: hoverTextColor,
                },
            },
        }}
        onClick={onClick}
        cursor={'pointer'}
    >
        <Button
            aria-label={text}
            variant="primary"
            height={height}
            bg={color}
            w="100%"
            color={textColor}
            _hover={{ bg: hoverColor, color: hoverTextColor }}
            borderRadius="md"
            {...rest}
        >
            <Text color="inherit" fontWeight="bold" fontSize={textButtonFontSize}>
                {text}
            </Text>
        </Button>

        {description && (
            <Text maxW="230" fontSize="xs" align="center" alignSelf="center" color="inherit" px="4" mt="1.5">
                {description}
            </Text>
        )}
    </Flex>
)

export default CtaButton

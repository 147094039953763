import React from 'react'

import { Link as ChakraUILink, LinkProps as LinkPropsChakra } from '@chakra-ui/react'
import { GatsbyLinkProps } from 'gatsby'
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next'

type GatsbyLinkPropsOverride = GatsbyLinkProps<Record<string, unknown>> & {
    language?: string
}

type LinkProps = LinkPropsChakra & GatsbyLinkPropsOverride
/**
 * Implement Link of gatsby-plugin-react-i18next Link with style of Chakra UI
 * @see https://www.gatsbyjs.com/plugins/gatsby-plugin-react-i18next/
 * @returns
 */
const Link = ({ children, ...rest }: LinkProps) => {
    return (
        <ChakraUILink as={GatsbyLink} {...rest}>
            {children}
        </ChakraUILink>
    )
}

export default Link

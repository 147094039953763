import React from 'react'

import { Button, ButtonProps } from '@chakra-ui/react'
import Link from '@components/ui/Link'

export interface OutlinedButtonProps extends ButtonProps {
    text: string
    link?: string
}

const OutlinedButton = ({ text, link, ...rest }: OutlinedButtonProps) => (
    <Button w="100%" variant="outline" _hover={{ bg: 'green.500', color: 'white' }} {...rest}>
        {link ? (
            <Link to={link} fontSize="xm" px="2.5" style={{ textDecoration: 'none' }}>
                {text}
            </Link>
        ) : (
            text
        )}
    </Button>
)

export default OutlinedButton
